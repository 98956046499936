body {
  background: #353853 url(/assets/blue_marble.gif);
  color: white;
}

.right-side-content {
  text-align: center;
}

.portrait {
  border: silver 7px dotted;
}

.theme-switch-wrapper {
  display: none;
}
