body {
  color: #fff;
  background: #353853 url("blue_marble.cc6eace5.gif");
}

.right-side-content {
  text-align: center;
}

.portrait {
  border: 7px dotted silver;
}

.theme-switch-wrapper {
  display: none;
}
/*# sourceMappingURL=index.116e16ba.css.map */
